<template>
  <div class="home_container">
    <!-- 首页轮播图 -->
    <van-swipe :autoplay="3000" class="my-swipe">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img v-lazy="image.mobileBannerUrl[0].url" width="100%" />
      </van-swipe-item>
    </van-swipe>
    <!-- 首页产品介绍 -->
    <div class="titlesame">
      <p>{{ titleContent[0].name }}</p>
      <p>{{ titleContent[0].ename }}</p>
    </div>
    <div class="productCategory" style="height: 40px; width: 90%">
      <ul style="display: flex; width: 100%; height: 100%">
        <li
          v-for="(item, index) in productCateGroy"
          :key="index"
          :class="currentIndex == item.id ? 'active' : ''"
          @click="changeIndex(item)"
        >
          {{ item.seriesName }}
        </li>
      </ul>
    </div>
    <div class="productList">
      <Bscroll
        :data="productList"
        :scrolltype="scrollX"
        :Passthrough="Passthrough"
        ref="scroll"
        :handleToend="handleToscroll"
      >
        <ul class="product-wrapper" ref="productWrapper">
          <router-link
            :to="path + JSON.stringify(item)"
            v-for="(item, index) in productList"
            :key="index"
            class="productItem"
          >
            <div class="poster">
              <img
                :src="
                  item.productImg && item.productImg.length > 0
                    ? item.productImg[0].url
                    : ''
                "
                alt
              />
            </div>
            <h4 class="name msgSame">{{ item.productName }}</h4>
            <p class="intro msgSame">{{ item.productIntroduction }}</p>
          </router-link>
        </ul>
      </Bscroll>
    </div>
    <!-- 公司简介 -->
    <div class="companyInfo">
      <div class="titlesame">
        <p>{{ titleContent[1].name }}</p>
        <p>{{ titleContent[1].ename }}</p>
      </div>
      <div class="img pad_left">
        <img
          :src="
            companyInfo.webCorporateImage &&
            companyInfo.webCorporateImage.length > 0
              ? companyInfo.webCorporateImage[0].url
              : ''
          "
          alt=""
        />
      </div>
      <div class="details pad_left">
        <!-- <h2>公司介绍</h2>
        <p>INTRODUCT</p> -->
        <p>{{ companyInfo.webCorporateIntroduce }}</p>
      </div>
    </div>

    <!-- 公司优势 -->
    <div class="advantages">
      <div>
        <van-grid :column-num="2" >
          <van-grid-item
            v-for="item in advantagelist"
            :key="item.id"
          >
          <div  class="title">
              <h2>{{item.advantageName}}</h2>
              <p>{{item.advantagePronoun}}</p>
          </div >
          <div class="Itemlist">
            <p v-for="items in item.advantageContent" :key="items.id">
               {{items}}
            </p>
          </div>
          </van-grid-item >
        </van-grid>
      </div>
    </div>
    <!-- 公司团队 -->
    <div class="companyTeam">
      <div class="titlesame">
        <p>{{ titleContent[2].name }}</p>
        <p>{{ titleContent[2].ename }}</p>
      </div>
      <Swiper :list="TeamList" />
    </div>
    <!-- 尾部 -->
    <FooterContainer />
  </div>
</template>

<script>
import Bscroll from "./common/scroll/Bscroll";
import Swiper from "./common/swiper/Aswiper.vue";
export default {
  name: "homeContainer",
  data() {
    return {
      path: "/Mproducts?data=",
      images: [], //轮播图图片
      productList: [], //产品列表数组
      productCateGroy: [], //产品类别数组
      TeamList: [], //团队配置
      seriesId: "",
      scrollX: true,
      activeIndex: "",
      currentIndex: 0,
      advantagelist:[],
      // currentIndexs:0,
      Passthrough: "vertical",
      pageSize: 6,
      pageIndex: 1,
      offset: 0, //记录一共有多少个值
      total: 0,
      T_total: 0,
      pageSizes: 4,
      pageIndexs: 1,
      // pageIndex_A:1,
      // pageSize_A:
      titleContent: [
        {
          name: "产品介绍",
          ename: "products",
        },
        {
          name: "公司介绍",
          ename: "INTRODUCT",
        },
        {
          name: "公司团队",
          ename: "Mentor team",
        },
      ],
      companyInfo: {},
    };
  },
  mounted() {
    this.getSwiperList();
    this.getProductsCategroy();
    this.getCompanyInfo();
    this.getCompanyTeam();
    this.advantageList();
  },
  methods: {
    //获取初始手机端轮播图数据
    async getSwiperList() {
      let res = await this.$api.getBannerList();
      let result = res.data;
      result.forEach((item) => {
        item.mobileBannerUrl = JSON.parse(item.mobileBannerUrl);
      });
      this.images = result;
    },
    //获取公司信息
    async getCompanyInfo() {
      let res = await this.$api.getCompanyInfo();
      if (res.code == 0) {
        if (res.data.webCorporateImage) {
          res.data.webCorporateImage = JSON.parse(res.data.webCorporateImage);
          console.log(res.data.webCorporateImage);
        }
        console.log(res.data);
        this.companyInfo = res.data;
      }
    },
    //获取团队信息列表
    async getCompanyTeam() {
      let result = await this.$api.getCompanyTeam(
        this.pageIndexs,
        this.pageSizes
      );
      console.log(result);
      this.T_total = Number(result.page.totalCount);
      let res = result.data;
      if (res.length > 0) {
        res.forEach(
          (item) => (item.teamPersonalImg = JSON.parse(item.teamPersonalImg))
        );
      }

      this.TeamList = res;
      console.log(this.TeamList);
    },
    //获取产品列表数据
    async getProductList() {
      let result = await this.$api.getProductList(
        this.seriesId,
        this.pageSize,
        this.pageIndex
      );
      console.log(result);
      if (result.code == 0) {
        this.total = Number(result.page.totalCount);
        let res = result.data;
        res.forEach((item) => {
          if (item.productImg) {
            item.productImg = JSON.parse(item.productImg);
          }
        });

        this.offset += res.length;
        this.productList = this.productList.concat(res);
        // this.$refs.productWrapper[0].style.width =
        // this.productList.length * 140 + "px";
        this.$refs.productWrapper.style.width =
          this.productList.length * 140 + "px";
      }
    },
    //获取产品分类数据
    async getProductsCategroy() {
      let result = await this.$api.getproductCategroy();
      console.log(result);
      if (result.code == 0) {
        this.productCateGroy = result.data;
        this.seriesId = result.data[0].id;
        this.currentIndex = result.data[0].id;
        this.getProductList();
      }
    },
    //获取公司优势信息
    async advantageList() {
      let result = await this.$api.getAdvantageList();
      console.log(result);
      if (result.code == 0) {
        result.data.forEach((item) => {
          item.advantageContent = JSON.parse(item.advantageContent);
        });
        this.advantagelist = result.data;
      }
    },

    //监听滚动位置
    handleToscroll(pos) {
      // const width = this.$refs.productWrapper[0].offsetWidth;
      const width = this.$refs.productWrapper.offsetWidth;
      console.log(pos.x, width);
      if (pos.x < -(width / 2)) {
        console.log(this.offset);
        if (this.offset == this.total) return;
        this.pageIndex++;
        console.log(this.pageIndex, this.seriesId);
        this.getProductList();
      }
    },
    //切换索引值获取对应的分类数据
    changeIndex(i) {
      const { id } = i;
      this.currentIndex = id;
      this.seriesId = id;
      this.pageIndex = 1;
      this.productList = [];
      this.getProductList();
    },
  },
  components: {
    Bscroll,
    Swiper,
  },
};
</script>
<style scoped lang="scss">
 .home_container::-webkit-scrollbar{
    display:none;
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 165px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.titlesame {
  height: 60px;
  padding: 30px 0 0 20px;
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    text-align: left;
    //  font-size:14px;
    &:first-of-type {
      font-size: 17px;
      line-height: 22px;
      color: #333;
    }
    &:last-of-type {
      font-size: 12px;
      line-height: 22px;
      color: #3333;
    }
  }
}
.productCategory {
  float: right;
  margin-right: 0;
  margin-bottom: 10px;
  ul {
    justify-content: space-evenly;
    li {
      // width: 60px;
      font-size: 16px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      &.active {
        color: #57b6d0;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          height: 4px;
          width: 18px;
          background-color: #57b6d0;
          opacity: 0.8;
        }
      }
    }
  }
}

.productList {
  height: 180px;
  width: 100%;
  overflow: hidden;
  .product-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    // box-sizing: border-box;
    &::-webkit-scrollbar {
      display: none;
    }
    .productItem {
      margin-left: 5px;
      display: inline-block;
      width: 120px;

      overflow: hidden;
      margin-right: 10px;
      .poster {
        width: 120px;
        height: 120px;
        position: relative;
        margin-bottom: 6px;
        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }
      .name {
        font-size: 10px;
        color: #222;
        margin: 0;
        margin-bottom: 5px;
        width: 120px;
        text-overflow: ellipsis;
      }
      .intro {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
      .msgSame {
        width: 100px;
        margin: 0 auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

//公司简介样式
.companyInfo {
  height: 500px;
  background-color: #f5f8fb;
  margin-top: 15px;
  box-sizing: border-box;
  .pad_left {
    padding: 0 20px;
  }
  .img {
    width: 100%;
    box-sizing: border-box;
    height: 165px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .details {
    height: 200px;
    overflow: hidden;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6; /*3表示只显示3行*/
      -webkit-box-orient: vertical;
      text-indent: 5px;
      line-height: 30px;
      font-size: 12px;
      color: #444;
      text-align: left;
    }
  }
}

//公司优势
.advantages {
  width: 100%;
  margin-bottom:30px;
  background-color: #eee;
  & /deep/.van-grid-item__content--center{
    justify-content: flex-start ;
  }
}
//公司团队样式
.companyTeam {
  width: 100%;
  height: 350px;
  background-color: #fff;
}
</style>
