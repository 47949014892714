<template>
  <div class="home_container">
       <!-- 首页轮播图 -->
    <div class="banner">
       <homeContainer />
    </div>
  </div>
</template>
<script>
import homeContainer  from 'components/homeContainer';
export default {
  name: "homeContent",
  data(){
      return{

      }
  },
  components:{
      homeContainer
  }
};
</script>
<style lang="scss" scoped>
.home_container {
  height: 100%;
  .banner{
      background-color: #fff;
  }
}
</style>